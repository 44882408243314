// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusCounterBadgeWithTooltip-module__whAOYF0JPPqypf5wImWS{font-size:12px;line-height:16px;padding:3px 4px}.StatusCounterBadgeWithTooltip-module__UUC4p_BqYaMopYIHRa9v{background:rgba(27,133,94,.15);border:1px solid var(--tag-border-success);border-radius:2px}.StatusCounterBadgeWithTooltip-module__fyDFoovJT0acPgjFziFJ{background:rgba(245,183,61,.18);border:1px solid var(--tag-border-warning);border-radius:2px}.StatusCounterBadgeWithTooltip-module__yQgtRwZ7DV9Utlc8DDSp{padding:3px 10px}.StatusCounterBadgeWithTooltip-module__jDi4B1sTt1K4CDpoNAyE,.StatusCounterBadgeWithTooltip-module__euBhdCuH3J0xCuU7ybfR{color:var(--tag-text-success)}.StatusCounterBadgeWithTooltip-module__EPuQHZVdhzwgNVx_FY4D,.StatusCounterBadgeWithTooltip-module__G7MZdjbpIDa4qPoJ4MgH{color:var(--tag-text-warning)}.StatusCounterBadgeWithTooltip-module__K8XPV_pJscneph6as3Em{width:auto}", "",{"version":3,"sources":["webpack://./components/StatusCounterBadgeWithTooltip/StatusCounterBadgeWithTooltip.module.scss"],"names":[],"mappings":"AAAA,4DACE,cAAA,CACA,gBAAA,CACA,eAAA,CAEA,4DACE,8BAAA,CACA,0CAAA,CACA,iBAAA,CAGF,4DACE,+BAAA,CACA,0CAAA,CACA,iBAAA,CAGF,4DACE,gBAAA,CAIJ,wHAEE,6BAAA,CAGF,wHAEE,6BAAA,CAGF,4DACE,UAAA","sourcesContent":[".element {\n  font-size: 12px;\n  line-height: 16px;\n  padding: 3px 4px;\n\n  &--link {\n    background: rgba(27, 133, 94, 0.15);\n    border: 1px solid var(--tag-border-success);\n    border-radius: 2px;\n  }\n\n  &--warning {\n    background: rgba(245, 183, 61, 0.18);\n    border: 1px solid var(--tag-border-warning);\n    border-radius: 2px;\n  }\n\n  &--padding {\n    padding: 3px 10px;\n  }\n}\n\n.element__text--link,\n.element__icon--link {\n  color: var(--tag-text-success);\n}\n\n.element__text--warning,\n.element__icon--warning {\n  color: var(--tag-text-warning);\n}\n\n.tooltip {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": "StatusCounterBadgeWithTooltip-module__whAOYF0JPPqypf5wImWS",
	"element--link": "StatusCounterBadgeWithTooltip-module__UUC4p_BqYaMopYIHRa9v",
	"element--warning": "StatusCounterBadgeWithTooltip-module__fyDFoovJT0acPgjFziFJ",
	"element--padding": "StatusCounterBadgeWithTooltip-module__yQgtRwZ7DV9Utlc8DDSp",
	"element__text--link": "StatusCounterBadgeWithTooltip-module__jDi4B1sTt1K4CDpoNAyE",
	"element__icon--link": "StatusCounterBadgeWithTooltip-module__euBhdCuH3J0xCuU7ybfR",
	"element__text--warning": "StatusCounterBadgeWithTooltip-module__EPuQHZVdhzwgNVx_FY4D",
	"element__icon--warning": "StatusCounterBadgeWithTooltip-module__G7MZdjbpIDa4qPoJ4MgH",
	"tooltip": "StatusCounterBadgeWithTooltip-module__K8XPV_pJscneph6as3Em"
};
export default ___CSS_LOADER_EXPORT___;
